
body {
  font-family: "HCo Gotham SSm",Helvetica,Arial,sans-serif;

  --background: #141526;
  --main-text: #fff;
  --red-text: #f9305b;

  background-color: var(--background);
  color: var(--main-text);

}

a {
  text-decoration: none;
  color: var(--main-text);
  text-transform: uppercase;
  cursor: pointer;
}

header {
  display: flex;
  align-items: center;
  padding: 30px;
  font-size: 1.6rem;
}

header ul {
  list-style: none;
  margin: 0;
}

header ul a {
  text-transform: capitalize !important;

}

.red {
  color: var(--red-text);
}


main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.roll {
  margin-top: 50px;
}


.progress-bar {

  background-color: transparent;
  cursor: pointer;
  color: var(--main-text);
  border: none;
  outline: none;
}

.progress-bar:active {
  transform: translateY(4px);

}

.roll .previous {
  margin-top: 20px;
}

.progress-bar h1 {
  font-size: 36px;
  font-weight: bold;
}

.progress-bar h2 {
  font-size: 24px;
}

.previous {
  font-size: 18px;

}

.progress-bar .center {
  position: relative;
  overflow: hidden;
}

.progress-bar .center .fill {
  position: absolute;
}


#modal-portal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(20, 21, 38, 0.7);

  display: flex;
  align-items: center;
  justify-content: center;
}


#modal-portal .modal {
  width: 400px;
  max-width: calc(100vw - 20px);
  padding: 30px;

  background-color: #000;
  margin: 10px;
}

.modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal .header h1 {
  margin: 0;
}

.modal .header .close {
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
}


@media (max-width: 768px) {

  header {
    padding: 10px 15px;
    font-size: 18px;
    text-align: center;
  }

  .roll {
    margin-top: 20px;
  }
}
